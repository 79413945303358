/* Styles for the loading screen*/
@use "zindex";
@use "theme";

#loading {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: var(--background-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  z-index: zindex.$loading;

  object, img {
    max-width: 400px;
    max-height: 400px;
    width: 80vw;
    height: 80vw;
    display: block;
  }
}

#loading.hide {
  opacity: 0;
  z-index: -100;
  transition: z-index 0.5s step-end, opacity 0.5 linear;
}

