@use "../style/theme";

@mixin vendor-prefix($name, $value) {
  @each $vendor in ('-webkit-', '-moz-', '-ms-', '-o-', '') {
    #{$vendor}#{$name}: #{$value};
  }
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  height: fit-content;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  padding: 0.5em 1em;
  background: var(--highlight-color);
  color: var(--text-inverse-color);
  margin: 0.5em;
  box-shadow: var(--shadow-box);
}

button:disabled,
input[type="button"]:disabled,
input[type="reset"]:disabled,
input[type="submit"]:disabled {
  background: var(--disabled-color);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--highlight-color);
  color: var(--text-light-color);
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  font-size: 3rem;
  height: 3rem;
  width: 3rem;
  line-height: 3rem;
  text-align: center;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  img {
    height: 75%;
    border-radius: 0;
    vertical-align: middle;
  }
}


a.button {
  @extend button;
  display: inline-block;
  font-size: unset;
  line-height: 1em;
  height: unset;
  width: unset;
  white-space: nowrap;
  text-decoration: none;
  padding: 0.5em 1em;

}

button.icon {
  background: none;
  outline: none;
  border: none;
  background: var(--highlight-color);
  color: var(--text-light-color);
  border-radius: initial;
  padding: 0;
  margin: 0;
  box-shadow: none;

  i {
      display: inline-block;
      width: 2rem;
      height: 2rem;
      line-height: 2rem;
      border: 2px solid var(--highlight-color);
      border-radius: 2rem;
  }
}

form {
  > div {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    align-items: flex-start;
    width: 90%;
    max-width: 400px;
    margin: auto;
    padding-top: 1rem;

    label {
      display: flex;
      position: relative;
      flex-grow: 1;

      select,
      textarea,
      input {
        width: 100%;
        border: none;
        border-bottom: 1px solid var(--text-color);
        color: var(--text-color);
        outline: none;
        background: transparent;
        box-shadow: 0 0 0 30px transparent inset !important;
        -webkit-box-shadow: 0 0 0 30px transparent inset !important;
      }

      select:focus,
      textarea:focus,
      input:focus {
        border-bottom: 1px solid var(--highlight-color);
      }

      span {
        position: absolute;
        left: 0;
        top: 0;
        color: var(--label-color);
      }

      /* TODO: switch from :valid to ::placeholder-shown
       * requires a placeholder to be set and remove 'required'
       */
      select ~ span,
      button ~ span,
      input[type="button"] ~ span,
      input[type="submit"] ~ span,
      input[type="reset"] ~ span,
      input[type="image"] ~ span,
      textarea:valid + span,
      textarea:focus + span,
      input:valid + span,
      input:focus + span {
        font-size: theme.$textFontSize * 0.8;
        transform: translateY(-100%);
      }
    }
  }

  > div.buttons {
    justify-content: space-evenly;

    button,
    i {
      font-size: 3rem;
      line-height: 3rem;
      margin: 0;
      padding: 1px 6px;
    }

    label {
      flex-grow: unset;
      > i {
        @extend button;
        padding: 1px 12px;
      }
    }
  }
}

.radio_bar  {
  display: flex;
  background: var(--neutral-color);
  justify-content: space-evenly;
  border: none;
  padding: 0;
  border-radius: 6px;
  user-select: none;

  &:empty {
    font-size:0;
  }

  input[type="radio"] {
    display: none;
  }

  input:checked + label {
    background: var(--highlight-color);
    color: var(--neutral-color);
    padding: 0 0.25em;
  }

  label {
    background: transparent;
    color: var(--text-light-color);
    border-radius: 6px;
    line-height: 36px;
    text-align: center;
    padding: 0 0.25em;
    margin: 0.3em 0.15em;
    &:first-of-type {
          margin-left: 0.3em;
    }

    &:last-of-type {
      margin-right: 0.3em;
    }
  }
}
