/*
@import url(https://fonts.googleapis.com/css?family=Muli:300,300i,400,400i,500,600);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville');
*/
@use "sass:color";
@import url(/style/third-party/muli.css);
@import url(/style/third-party/material-icons.css);
@import url(/style/third-party/libre-baskerville.css);

$lightOrange:         hsl( 28.0,  88.5%,  59.2%);
$orange:              hsl( 28.1,  91.7%,  52.5%);
$amber:               hsl( 47.4, 100.0%,  62.5%);
$green:               hsl(133.7,  61.4%,  40.6%);
$teal:                hsl(168.1,  75.7%,  42.0%);
$lightTeal:           hsl(174.4,  41.9%,  64.9%);
$merc:                hsl(174.4, 100.0%,  41.2%);
$lightBlue:           hsl(188.2,  77.8%,  40.6%);
$darkBlue:            hsl(211.1, 100.0%,  50.0%);
$gray:                hsl(229.6,   9.7%,  46.5%);
$lightGreen:          hsl(134.7,  91.8%,  38.4%);
$lightGray:           hsl(227.4,   9.6%,  61.4%);
$lightIndigo:         hsl(230.5,  44.1%,  63.5%);
$pink:                hsl(339.6, 100.0%,  62.5%);
$red:                 hsl(354.3,  70.5%,  53.5%);
$black:               hsl(  0.0,   0.0%,   0.0%);
$darkGray:            hsl(  0.0,   0.0%,  20.0%);
$neutral:             hsl(  0.0,   0.0%,  32.5%);
$neutral-lighter:     hsl(  0.0,   0.0%,  46.3%);
$neutral-muchlighter: hsl(  0.0,   0.0%,  65.1%);
$offWhite:            hsl(  0.0,   0.0%,  97.6%);
$white:               hsl(  0.0,   0.0%, 100.0%);



$textFontFamily: 'Muli', sans-serif;
$sansFontFamily: 'Muli', sans-serif;
$serifFontFamily: 'Libre Baskerville', serif;
$textFontSize: 16px;
$iconWidth: 3rem;
$inputWidth: 3rem;
$sidenavWidth: 260px;
$mediaWide: 993px;


/*
 * These theme color names represent the symantic purpose.
 */
 @mixin theme-light {
  $background: $offWhite;
  $shadow: $black;
  --accent-color: #{$orange};
  --background-color: #{$background};
  --background-floating-color: #{$background};
  --background-a05: #{rgba($background, 0.05)};
  --background-a10: #{rgba($background, 0.10)};
  --background-a15: #{rgba($background, 0.25)};
  --background-a20: #{rgba($background, 0.20)};
  --background-a25: #{rgba($background, 0.25)};
  --background-a30: #{rgba($background, 0.30)};
  --background-a35: #{rgba($background, 0.35)};
  --background-a40: #{rgba($background, 0.40)};
  --background-a45: #{rgba($background, 0.45)};
  --background-a50: #{rgba($background, 0.50)};
  --background-a55: #{rgba($background, 0.55)};
  --background-a60: #{rgba($background, 0.60)};
  --background-a65: #{rgba($background, 0.65)};
  --background-a70: #{rgba($background, 0.70)};
  --background-a75: #{rgba($background, 0.75)};
  --background-a80: #{rgba($background, 0.80)};
  --background-a85: #{rgba($background, 0.85)};
  --background-a90: #{rgba($background, 0.90)};
  --background-a95: #{rgba($background, 0.95)};
  --disabled-color: #{$neutral-muchlighter};
  --header-color: #{$darkGray};
  --highlight-color: #{$teal};
  --highlight-lighter-color: #{$lightTeal};
  --input-focus-shadow-color: #{$pink};
  --input-valid-shadow-color: #{$lightGreen};
  --label-color: #{$lightGray};
  --mask-color: #{rgba($shadow, 0.50)};
  --neutral-color: #{$neutral};
  --neutral-lighter-color: #{$neutral-lighter};
  --neutral-muchlighter-color: #{$neutral-muchlighter};
  --shadow-a05: #{rgba($shadow, 0.05)};
  --shadow-a10: #{rgba($shadow, 0.10)};
  --shadow-a15: #{rgba($shadow, 0.15)};
  --shadow-a20: #{rgba($shadow, 0.20)};
  --shadow-a25: #{rgba($shadow, 0.25)};
  --shadow-a30: #{rgba($shadow, 0.30)};
  --shadow-a35: #{rgba($shadow, 0.35)};
  --shadow-a40: #{rgba($shadow, 0.40)};
  --shadow-a45: #{rgba($shadow, 0.45)};
  --shadow-a50: #{rgba($shadow, 0.50)};
  --shadow-a55: #{rgba($shadow, 0.55)};
  --shadow-a60: #{rgba($shadow, 0.60)};
  --shadow-a65: #{rgba($shadow, 0.65)};
  --shadow-a70: #{rgba($shadow, 0.70)};
  --shadow-a75: #{rgba($shadow, 0.75)};
  --shadow-a80: #{rgba($shadow, 0.80)};
  --shadow-a85: #{rgba($shadow, 0.85)};
  --shadow-a90: #{rgba($shadow, 0.90)};
  --shadow-a95: #{rgba($shadow, 0.95)};
  --shadow-box: #{0 2px 2px 0 rgba($shadow, .14), 0 3px 1px -2px rgba($shadow, .12), 0 1px 5px 0 rgba($shadow, .2)};
  --text-color: #{$gray};
  --text-light-color: #{$offWhite};
  --text-inverse-color: #{$offWhite};
  --text-emphasis-color: #{$darkGray};
}

@mixin theme-dark {
  $background: $darkGray;
  $shadow: $white;
  --accent-color: #{$teal};
  --background-color: #{$background};
  --background-floating-color: #{$background};
  --background-a05: #{rgba($background, 0.05)};
  --background-a10: #{rgba($background, 0.10)};
  --background-a15: #{rgba($background, 0.25)};
  --background-a20: #{rgba($background, 0.20)};
  --background-a25: #{rgba($background, 0.25)};
  --background-a30: #{rgba($background, 0.30)};
  --background-a35: #{rgba($background, 0.35)};
  --background-a40: #{rgba($background, 0.40)};
  --background-a45: #{rgba($background, 0.45)};
  --background-a50: #{rgba($background, 0.50)};
  --background-a55: #{rgba($background, 0.55)};
  --background-a60: #{rgba($background, 0.60)};
  --background-a65: #{rgba($background, 0.65)};
  --background-a70: #{rgba($background, 0.70)};
  --background-a75: #{rgba($background, 0.75)};
  --background-a80: #{rgba($background, 0.80)};
  --background-a85: #{rgba($background, 0.85)};
  --background-a90: #{rgba($background, 0.90)};
  --background-a95: #{rgba($background, 0.95)};
  --disabled-color: #{$neutral-muchlighter};
  --header-color: #{$offWhite};
  --highlight-color: #{$orange};
  --highlight-lighter-color: #{$lightOrange};
  --input-focus-shadow-color: #{$pink};
  --input-valid-shadow-color: #{$lightGreen};
  --label-color: #{$lightGray};
  --mask-color: #{rgba(0, 0, 0, 0.5)};
  --neutral-color: #{$neutral};
  --neutral-lighter-color: #{$neutral-lighter};
  --neutral-muchlighter-color: #{$neutral-muchlighter};
  --shadow-a05: #{rgba($shadow, 0.05)};
  --shadow-a10: #{rgba($shadow, 0.10)};
  --shadow-a15: #{rgba($shadow, 0.15)};
  --shadow-a20: #{rgba($shadow, 0.20)};
  --shadow-a25: #{rgba($shadow, 0.25)};
  --shadow-a30: #{rgba($shadow, 0.30)};
  --shadow-a35: #{rgba($shadow, 0.35)};
  --shadow-a40: #{rgba($shadow, 0.40)};
  --shadow-a45: #{rgba($shadow, 0.45)};
  --shadow-a50: #{rgba($shadow, 0.50)};
  --shadow-a55: #{rgba($shadow, 0.55)};
  --shadow-a60: #{rgba($shadow, 0.60)};
  --shadow-a65: #{rgba($shadow, 0.65)};
  --shadow-a70: #{rgba($shadow, 0.70)};
  --shadow-a75: #{rgba($shadow, 0.75)};
  --shadow-a80: #{rgba($shadow, 0.80)};
  --shadow-a85: #{rgba($shadow, 0.85)};
  --shadow-a90: #{rgba($shadow, 0.90)};
  --shadow-a95: #{rgba($shadow, 0.95)};
  --shadow-box: #{0 2px 2px 0 rgba($shadow, .14), 0 3px 1px -2px rgba($shadow, .12), 0 1px 5px 0 rgba($shadow, .2)};  
  --text-color: #{$offWhite};
  --text-light-color: #{$white};
  --text-inverse-color: #{$white};
  --text-emphasis-color: #{$gray};
}

.theme-light {
  @include theme-light;
}

@media (prefers-color-scheme: light) {
  .theme-system {
    @include theme-light;
  }
}

.theme-dark {
  @include theme-dark;
}

@media (prefers-color-scheme: dark) {
  .theme-system {
    @include theme-dark;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
