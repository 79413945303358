/* styles for the dialog box */
@use "form";
@use "theme";
@use "zindex";

#dialog {
  display: none;
  z-index: zindex.$dialog;

  #dialog-frame {
    background: var(--background-color);
    border-radius: 10px;
    width: 90vw;
    z-index: 9000;
    overflow: hidden;

    #dialog-content {
      padding: 0 1em 1em 1em;
    }

    #dialog-title {
      display: flex;
      justify-content: flex-end;

      #dialog-button-min {
        display: none;
      }

      i {
        @extend .button;
        background: unset;
        color: unset;
        height: 1em;
        width: 1em;
        padding: 0.1em;
        line-height: 2rem;
        font-size: 2rem;
      }
    }
  }

  #dialog-frame.max {
    min-height: 100vh;
    min-width: 100vw;
    border-radius: 0px;

    #dialog-title {
      #dialog-button-max {
        display: none;
      }

      #dialog-button-min {
        display: inline-flex;
      }
    }
  }
}

#dialog.show {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--mask-color);
  backdrop-filter: blur(2px);
  justify-content: center;
  align-items: center;
}
