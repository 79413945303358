horizontal-swiper > button {
  display: flex !important;
  height: 3em;
  width: 3em;
  margin: 0.5rem;
  line-height: 3em !important;
  border: 0;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
}

horizontal-swiper .indicators {
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

horizontal-swiper .indicators i {
  display: inline-block;
  border-radius: 50%;

  border: 2px solid rgba(255,255,255,0.75);
  background-color: rgba(0,0,0,0.75);
  pointer-events: all;
  height: 1rem;
  width: 1rem;
}

horizontal-swiper .indicators i.active {
  border-color: rgba(0,0,0,0.75);
  background-color: rgba(255,255,255,0.75);
}
