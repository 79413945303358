@use "theme";
@use "zindex";

.menu {
  background: transparent;

  &.show {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--mask-color);
    z-index: zindex.$mask;
    transition: background 0.1s;
  }

  > ul {
    list-style: none;
    background: rgba(255,255,255,0.95);
    position: fixed;
    bottom: 0;
    padding: 0;
    margin: 0;
    margin-left: 2rem;
    box-sizing: border-box;
    width: calc(100% - 4rem);
  }

  &.show > ul {
    border: 1px solid var(--text-emphasis-color);
    border-radius: 6px 6px 0 0;
    > li {
      height: 2rem;
      max-height: 2rem;
      line-height: 2rem;
      font-size: 1.5rem;
      margin: 0.5rem;
      transition: max-height 0.1s;

      &:last-child {
        margin-bottom: 2rem;
      }
    }
  }

  &.hide > ul {
    border: none;
    border-radius: 6px 6px 0 0;
    transition: step-end;
    > li {
      max-height: 0;
      transition: max-height 0.1s;
    }
  }
}

.floating-menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 1rem;
  top: calc(100vh - 8rem);
  cursor: pointer;
  align-items: center;
  margin: 0;
  padding: 0.5rem;
  width: 3rem;
  border-radius: 2rem 2rem;
  background: var(--highlight-color);
  color: var(--text-light-color);
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;

  i {
    cursor: pointer;
    font-size: 3rem;
    line-height: 3rem;
    user-select: none;
  }

  > input[type="checkbox"] {
    display:none;

    ~ label > i {
      transition-duration: 0.25s
    }

    ~ div {
      i {
        max-height: 0;
        transition-duration: 0.25s
      }
    }

    &:checked {
      ~ label > i {
        transform: scale(0.5);
        transition-duration: 0.25s
      }

      ~ div {
        i {
          max-height: 3rem;
          transition-duration: 0.25s
        }
      }
    }
  }

  div {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}
