/* Styles for document module */
.navigating {
  opacity: 0;
}

.done_navigating {
  opacity: 1;
  transition-property: opacity;
  transition-duration: 0.5s;
}
