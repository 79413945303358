// Stack blocks on top of each other
.stack {
  position: relative;
  font-size: 0;

  > * {
    position: relative;
    display: block;

    &:not(:first-child) {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

