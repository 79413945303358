/* debug button style */
@use "zindex";

.debug_button {
  display: block;
  position: fixed;
  left: 1rem;
  z-index: zindex.$debug;
}

:not(.debugging) {
  .debug_on{
    display: none !important;
  }
}

.debugging {
  a.button.debug_on {
      display: inline-block !important;
    }

  a.button.debug_off {
      display: none !important;
  }
}

