@use "theme";

.toast {
  transition: all 0.5s ease-out;
  padding: 1em;
  margin: 0 auto;
  left: 10vw;
  width: 80vw;
  text-align: center;
  boxizing: border-box;
  border-radius: 6px;
  background: var(--background-color);
  border: 2px solid var(--highlight-color);
}


