@use "theme";
@use "zindex";

#mask {
  display: none;
}

#mask.show {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,.5);
  z-index: zindex.$mask;
}

