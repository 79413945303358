@use 'material-icons';
@use "form";
@use "debug";
@use "dialog";
@use "document";
@use "loading";
@use "horizontal-swiper";
@use "mask";
@use "menu";
@use "stack";
@use "theme";
@use "toast";

body {
  position: fixed;
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
  font-family: theme.$textFontFamily;
  font-size: theme.$textFontSize;
  color: var(--text-color);
  background: var(--background-color);
}

em {
  font-family: theme.$serifFontFamily;
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

i {
  @extend .material-icons !optional;
  line-height: 1em;
  font-size: 1em;

  &:empty {
    width: 1em;
  }

  img {
    height: 1em;
    border-radius: 0.5em;
  }
}

i.inline {
  width: 1em !important;
  height: 1em !important;
  font-size: 1em !important;
  line-height: 1em !important;
}

.oneline {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
}

h1,
h2,
h3,
h4,
h5,
h6
{
  font-family: theme.$textFontFamily;
  font-weight: 400;
  line-height: 1.1;
  color: var(--header-color);
  text-align: center;
}
h1
{
  font-size: 4.2rem;
  line-height: 110%;
  margin: 2.1rem 0 1.68rem 0;
}

h2
{
  font-size: 3.56rem;
  line-height: 110%;
  margin: 1.78rem 0 1.424rem 0;
}

h3
{
  font-size: 2.92rem;
  line-height: 110%;
  margin: 1.46rem 0 1.168rem 0;
}

h4
{
  font-size: 2.28rem;
  line-height: 110%;
  margin: 1.14rem 0 .912rem 0;
}

h5
{
  font-size: 1.64rem;
  line-height: 110%;
  margin: .82rem 0 .656rem 0;
}

h6
{
  font-size: 1.15rem;
  line-height: 110%;
  margin: .575rem 0 .46rem 0;
}

#page {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

/* Displays the element if the parent is empty */
.if-empty {
  display: none;
}
.if-empty:first-child {
  display: unset;
}

.connect_button.connected {
  color: var(--highlight-color);
}
